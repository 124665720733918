<template>
  <div class="comp_exp_incidencias conflex pt-3"
       style="justify-content:center;background-color:#eee;min-width:700px">
     
     <!-- divido en dos columnas -->
    <!-- <div
      if="schema.ctrls.id.value !== ''"
        class="comp_exp_incidencias conflex "
        style=" justify-content:center;background-color: #eee;"
      
    > -->
      <!-- columnas  apd1 apd2 incidencias y debajo observaciones -->

      <div class="columna">
        <div class="conflex">

          <div class="columna">
            <div class="cab">APD 1 CHECKS</div>
            <v-sheet  v-bind="$cfg.styles.marco">
              <compfecha
                :schema="schema.ctrls.chkcom1"
                :edicion="is_edit && app==1 && record.fune1>0 && !record.chkcom1"
              ></compfecha>
              <compfecha
                :schema="schema.ctrls.chkcall1"
                :edicion="is_edit1 && !record.chkcall1"
              ></compfecha>
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.chklugar1.value"
                :label="schema.ctrls.chklugar1.label"
                :disabled="!is_edit1"
              ></v-text-field>
              <ctrlgeo
                :schema="schema"
                name_ctrl="geo_fam1"
                :edicion="is_edit1"
              ></ctrlgeo>
              <ctrlgeo
                :schema="schema"
                name_ctrl="geo_iap1"
                :edicion="is_edit1"
              ></ctrlgeo>
              <compfecha
                :schema="schema.ctrls.chkfun1"
                :edicion="is_edit && app==1"
              ></compfecha>
              <compfecha
                :schema="schema.ctrls.chkterm1"
                :edicion="is_edit1 && record.fune1>0 && !record.chkterm1"
              ></compfecha>
            </v-sheet>
          </div>

          <div class="columna">
            <div class="cab">APD 2 CHECKS</div>
            <v-sheet v-bind="$cfg.styles.marco" >
              <compfecha
                :schema="schema.ctrls.chkcom2"
                :edicion="is_edit && app==1 && record.fune2>0 && !record.chkcom2"
              ></compfecha>
              <compfecha
                :schema="schema.ctrls.chkcall2"                
                :edicion="is_edit2 && !record.chkcall2"
              ></compfecha>
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.chklugar2.value"
                :label="schema.ctrls.chklugar2.label"
                :disabled="!is_edit2"
              ></v-text-field>
              <ctrlgeo
                :schema="schema"
                name_ctrl="geo_fam2"
                :edicion="is_edit2"
              ></ctrlgeo>
              <ctrlgeo
                :schema="schema"
                name_ctrl="geo_iap2"
                :edicion="is_edit2"
              ></ctrlgeo>
              <compfecha
                :schema="schema.ctrls.chkfun2"
                :edicion="is_edit && app==1"
              ></compfecha>
              <compfecha
                :schema="schema.ctrls.chkterm2"
                :edicion="is_edit2 && record.fune2>0 && !record.chkterm2"
              ></compfecha>
            </v-sheet>
          </div>
                    
          <div class="columna">
            <div class="cab" style="display:flex">
              <div style="flex: 0 32px">?</div>
              <div style="text-align:center">INCIDENCIAS</div>
            </div>

            <div class="conflex">
              <!-- dos checks por fila-->
              <v-sheet v-bind="$cfg.styles.marco">
                <div style="flex: 1 1 auto">
                  <div style="display:flex">
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkocultacion_p.value"
                      :disabled="!is_edit"
                      :readonly="schema.ctrls.chkocultacion.value=='1'"
                      @change="schema.ctrls.chkocultacion_p.value== '1'? 
                        $emit('onEvent', { 
                          accion:'confirmar_incidencia',
                          pregunta:'¿Está seguro CHECK <b>Posible Ocultación</b>?',
                          fnOk:'confirmacion_pOcultacion',
                          fnCancel:'cancelo_confirmacion_pOcultacion'}) : 

                        $emit('onEvent', { 
                          accion:'borrar_incidencia',
                          pregunta:'¿Está seguro CANCELAR CHECK <b>Posible Ocultación</b>?',
                          fnOk:'eliminacion_pOcultacion',
                          fnCancel:'cancelo_eliminacion_pOcultacion'})">
                    </v-checkbox>

                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkocultacion.value"
                      :label="schema.ctrls.chkocultacion.label"
                      :disabled="!is_edit || rol!='jz'"
                      @change="schema.ctrls.chkocultacion.value== '1'? 
                        $emit('onEvent', { 
                          accion:'confirmar_incidencia',
                          pregunta:'¿Está seguro CHECK <b>Ocultación</b>?',
                          fnOk:'confirmacion_ocultacion',
                          fnCancel:'cancelo_confirmacion_ocultacion'}) : 

                        $emit('onEvent', { 
                          accion:'borrar_incidencia',
                          pregunta:'¿Está seguro CANCELAR CHECK <b>Ocultación</b>?',
                          fnOk:'eliminacion_ocultacion',
                          fnCancel:'cancelo_eliminacion_ocultacion'})">
                    </v-checkbox>
                  </div>

                  <div style="display:flex">                    
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkreclamacion_p.value"
                      :disabled="!is_edit || app==1"
                      :readonly="schema.ctrls.chkreclamacion.value=='1' || (schema.ctrls.chkreclamacion_p.value=='1' && rol=='apds')"
                      @change="changeReclamacion">
                    </v-checkbox>

                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkreclamacion.value"
                      :label="schema.ctrls.chkreclamacion.label"
                      :disabled="!is_edit || app!=1 || (app==1 && $store.state.G.USUDAT.ndep<='2')">
                    </v-checkbox>
                  </div>
                  <v-divider class="py-2"></v-divider>

                  <div style="display:flex">                    
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkrenuncia.value"
                      :label="schema.ctrls.chkrenuncia.label"
                      :disabled="!is_edit"
                    ></v-checkbox>
                  </div>

                  <div style="display:flex">
                    
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkdevolucion.value"
                      :label="schema.ctrls.chkdevolucion.label"
                      :disabled="!is_edit"
                    ></v-checkbox>
                  </div>

                  <div style="display:flex">
                    
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkbloq.value"
                      :label="schema.ctrls.chkbloq.label"
                      :disabled="!is_edit || ndep<'6'"
                    ></v-checkbox>
                  </div>

                  <v-divider class="pt-3 pb-2"></v-divider>

                  <div style="display:flex">                                
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkcapital.value"
                      :label="schema.ctrls.chkcapital.label"
                      :disabled="!is_edit || ndep<3 || ((schema.ctrls.chkautoriza_no.value=='1'||schema.ctrls.chkautoriza.value=='1') && ndep<3)"
                      
                      @change="schema.ctrls.chkcapital.value== '0' && schema.ctrls.motivo_insufcapital.value>0? 
                        $emit('onEvent', { 
                        accion:'borrar_incidencia',
                        pregunta:'¿Está seguro CANCELAR CHECK Insuficiencia Capital?<br>Se eliminará el motivo y su posterior autorización', 
                        fnOk:'eliminacion_insuf',
                        fnCancel:'cancelo_eliminacion_insuf'}) : ''">
                    </v-checkbox>
                  </div>
                   
                  <div style="display:flex">
                    <v-select                    
                      v-bind="$select"
                      v-model="schema.ctrls.motivo_insufcapital.value"
                      label="Motivo Insuficiencia"
                      :items="motivosInsuficiencia"
                      :disabled="!is_edit || schema.ctrls.chkcapital.value!='1' || schema.ctrls.motivo_insufcapital.value> 0"
                      item-value="id"
                      item-text="name"
                      @change="$emit('onEvent', { 
                        accion:'confirmar_incidencia', 
                        pregunta:'¿Está seguro CHECK <b>Capital Insuficiente</b>?<br>Se enviará un SMS al Coordinador del Servicio', 
                        fnOk:'confirmacion_insuf',
                        fnCancel:'cancelo_confimacion_insuf',
                        motivos:motivosInsuficiencia})">
                    </v-select>
                  </div>                  

                  <div style="display:flex">
                    <span class="ml-1 light-gray--text text--darken-3">No</span>
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkautoriza_no.value"
                      :label="schema.ctrls.chkautoriza_no.label"
                      :disabled="rol!='JZ'|| !is_edit"
                    ></v-checkbox>

                    <span class="light-gray--text text--darken-1">Si</span>
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.chkautoriza.value"
                      label=""
                      :disabled="rol!='JZ'|| !is_edit">
                    </v-checkbox>

                    <autorizacion_CI
                      :ctrls="schema.ctrls"
                      :record="record"
                      :disabled="is_edit">
                    </autorizacion_CI>
                  </div>
                </div>
              </v-sheet>
            </div>
          </div>
        </div>
        <!-- fin columnas flex APD1 APD2 INCIDENCIAS -->
   
        <div class="cab">CIERRES</div>
        <div class="conflex">
           
          <v-sheet v-bind="$cfg.styles.marco">
            <div style="display:flex">
              <!-- <v-btn rounded 
                icon 
                @click="aperturaCierre_chkconf24"
                :disabled="!is_edit || schema.ctrls.chkconfadmon.value=='1' || schema.ctrls.chkconfcierre.value=='1'">
                  <v-icon>{{ schema.ctrls.chkconf24.value== "0"? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-outline'}}</v-icon>
              </v-btn> -->
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.chkca24.value"
                label="CTD"
                disabled>
              </v-text-field>
            </div>

            <div style="display:flex">
              <!-- <v-btn rounded 
                icon 
                @click="aperturaCierre_chkconfadmon"
                :disabled="!is_edit || schema.ctrls.chkconf24.value=='0' || schema.ctrls.chkconfcierre.value=='1' || $store.state.G.USUDAT.ndep<'4'">
                  <v-icon>{{ schema.ctrls.chkconfadmon.value== "0"? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-outline'}}</v-icon>
              </v-btn> -->
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.chkadmon.value"
                label="ADMON"
                disabled>
              </v-text-field>
            </div>

            <div style="display:flex;">
              <!-- <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.chkconfcierre.value"
                :disabled="true"
                readonly>                
              </v-checkbox> -->
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.chkcierre.value"
                label="CIERRE"
                disabled>
              </v-text-field>
            </div>         
          </v-sheet>
                  
          <div>
            <div class="conflex">
              <v-sheet v-bind="$cfg.styles.marco" style="width:562px;">
                
                  <div class="columna">
                    <ctrlobs3              
                      :schemaComponente="schema.ctrls.chkdef"
                      :schema="schema"
                      :record="record"
                      :edicion="is_edit">
                    </ctrlobs3>  
                  </div>
              
              </v-sheet>
            </div>
          </div>
          
        </div>          
      </div> 
        <!-- fin columnas flex APD1 APD2 INCIDENCIAS observaciones observaciones -->
        <!-- fin division izquierda -->     

       <!-- ----------------------------------------------------------------------------------------------------------------------->

      <!-- columna observaciones -->
      <div class="columna">     
        
        <v-sheet v-bind="$cfg.styles.marco" style="width:500px" >        
          <ctrlobs3
            :schemaComponente="schema.ctrls.obs3_incidencias"
            :schema="schema"  
            :record="record"          
            :edicion="is_edit"
            agrupar
          ></ctrlobs3>
        </v-sheet>
        
        <v-sheet v-bind="$cfg.styles.marco" >
          <ctrlobs3
            :schemaComponente="schema.ctrls.chkautoriza_obs"
            :schema="schema"
            :record="record"
            :edicion="is_edit && rol=='jz'">
          </ctrlobs3>           
        </v-sheet>

        <!-- CierreApertura -->
        <component
          v-if="$store.state[padre].record"
          :expRecord="$store.state[padre].record"
          :facturas="this.$store.state[padre].recordsDetalle[2]"
          :check="cierreAperturaId"
          :opcion="cierreApertura_opcion"
          :is="component_cierreApertura"
          @event_cierreApertura="event_cierreApertura">        
        </component>

        <div class="columna" style="width:320px;">
          <div class="cab">ÚLTIMAS ACTUALIZACIONES</div>
          <v-sheet v-bind="$cfg.styles.marco">
            <div class="columna">
              
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.op_last.value"
                :label="schema.ctrls.op_last.label"
                readonly>
              </v-text-field>                 

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.apd_last.value"
                :label="schema.ctrls.apd_last.label"
                readonly>
              </v-text-field>
              
            </div>              
          </v-sheet>
        </div>

        <!-- <div class="columna">
        <div class="cab">ULTIMOS CAMBIOS</div>
        <v-sheet v-bind="$cfg.styles.marco" style="height:100px;width:275px">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.op_last.value"
            label="CTD"
            :disabled="!is_edit"
          ></v-text-field>
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.apd_last.value"
            label="APD"
            :disabled="!is_edit"
          ></v-text-field>
        </v-sheet>
        </div> -->
       
      </div>
    </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const compfecha = () => plugs.groute("compfecha.vue", "comp");  
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const ctrlgeo = () => plugs.groute("ctrlgeo.vue", "comp");
  const autorizacion_CI = () => plugs.groute("autorizacion_CI.vue", "comp");
  const exp_cierreApertura = () => plugs.groute("exp_cierreApertura.vue", "comp");

  export default {
    components: { compfecha, ctrlobs3, ctrlgeo, autorizacion_CI, exp_cierreApertura },
    mixins: [mixinCtrls],
    props: {
      padre: { type:String, default: '' },      
      componenteTipo: { type:String, default:'M' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema:null,
        motivosInsuficiencia:[],
        component_cierreApertura: null,
        cierreApertura_opcion:'',
        cierreAperturaId:''
      }
    },

    created(){
      this.ini_data();
    },
 
    methods:{

      aperturaCierre_chkconfadmon(){        
        this.cierreAperturaId='chkadmon';
        this.cierreApertura_opcion= this.schema.ctrls.chkconfadmon.value=="0"?'cierre':'apertura';
        this.component_cierreApertura = "exp_cierreApertura";     
      },
      
      aperturaCierre_chkconf24() {        
        //validamos
        if (!this.validarAperturaCierreCtd()) return;        

        this.cierreAperturaId='chkca24';
        this.cierreApertura_opcion= this.schema.ctrls.chkconf24.value=="0"?'cierre':'apertura'
        this.component_cierreApertura = "exp_cierreApertura";
      },

      validarAperturaCierreCtd() {        
        let msg='';
        var F=this.schema.ctrls;  console.log("* validarAperturaCierre", F);

        //cierre CTD
        if (F.chkconf24.value=="0") {

            //si bloqueado por Jefe de zona (chkbloq)
            if (F.chkbloq.value=='1') {
              msg+="* BLOQUEADO POR COORDINADOR DE SERVICIO<br>";
            }            

            //si prestamos apd y no es renuncia comprobamos conformidad funeraria por cada apd
            if (F.chkrenuncia.value=='0' && F.p_apd.value=='1') {
                if (F.apd1.value>0 && F.chkfun1.value=='') msg+="* FALTA CONFORMIDAD FUNERARIA (1)<br>";
                if (F.apd2.value>0 && F.chkfun2.value=='') msg+="* FALTA CONFORMIDAD FUNERARIA (2)<br>";
            }
            
            //insuf.capital
            if (F.chkcapital.value=='1' && F.chkautoriza.value=='0' && F.chkautoriza_no.value=='0') 
                msg+="* INSUFICIENCIA DE CAPITAL SIN AUTORIZAR<br>";
            
            //cs aplicado
            if (F.cs_aplica.value=='1' && F.cs_aplicajz.value=='0') 
                msg+="* CS SIN AUTORIZAR POR COORDINADOR DE SERVICIO<br>";   

            //fin de compras y que haya facturas (excepcion mapfre asistencia)            
            if (F.callemp.value!='84' && 
                F.chkpresapd.value=='0' && this.$store.state[this.padre].recordsDetalle[2].length)
                msg+="* FIN DE COMPRAS NO MARCADO<br>";                
                
            //
        }

        //mostramos ERRORES producidos
        if (msg>'') {
          this.$root.$alert.open("IMPOSIBLE CIERRE CTD<hr><br>" + msg, 'error');          
        }
        
        return (msg=='');
      },

      event_cierreApertura(evt) {
      
        this.component_cierreApertura=null;
        if (evt.obs=='') return ;

        if (this.cierreAperturaId=='chkadmon') this.schema.ctrls.chkconfadmon.value= this.schema.ctrls.chkconfadmon.value=="1"? "0" : "1";
        if (this.cierreAperturaId=='chkca24') this.schema.ctrls.chkconf24.value= this.schema.ctrls.chkconf24.value=="1"? "0" : "1";

        const fecha = new Date();
        const sep = "-";
        const prefix= "[";
        const sufix="]"

        if (this.cierreAperturaId=='chkca24'){
            this.schema.ctrls.chkca24.value=`${prefix}${this.$store.state.G.USUARIO} ${String(fecha.getHours()).padStart(2, "0")}:${String(fecha.getMinutes()).padStart(2, "0")} ${String(fecha.getDate()).padStart(2, "0")}${sep}${String(fecha.getMonth() + 1).padStart(2, "0")}${sep}${fecha.getFullYear()}${sufix}`
            this.schema.ctrls.chkctd_usu.value=this.$store.state.G.USUARIO;
            this.schema.ctrls.chkctd.value='now';
        }
        if (this.cierreAperturaId=='chkadmon'){
            this.schema.ctrls.chkadmon.value=`${prefix}${this.$store.state.G.USUARIO} ${String(fecha.getHours()).padStart(2, "0")}:${String(fecha.getMinutes()).padStart(2, "0")} ${String(fecha.getDate()).padStart(2, "0")}${sep}${String(fecha.getMonth() + 1).padStart(2, "0")}${sep}${fecha.getFullYear()}${sufix}`
            // this.schema.ctrls.chkadmon.value=this.schema.ctrls.chkadmon.value=this.indentificacion()
            // <!-- @change="indentifica(schema.ctrls.chkca24)"> -->¡
            this.schema.ctrls.chkadmon_usu.value=this.$store.state.G.USUARIO;
            this.schema.ctrls.chkadmon0.value='now';
        }
        this.schema.ctrls.chkdef.comp.valueaux=evt.obs;
      },

      ini_data() {

        // guardo del store en variables locales
        this.schema = this.$store.state[this.padre].schema;
        // items Insuficiencia Capital 
        this.motivosInsuficiencia= this.$store.state.datos_iniciales.motivos_insufcapital;
        this.motivosInsuficiencia.unshift({ id:"0", name:'Selecciona' });
      },

      // cierre CTD
    
    //  identificacion(prefix= "[", sufix="]") {
    //   const fecha = new Date();
    //   const sep = "-";

    //   return `${prefix}${this.$store.state.G.USUARIO} ${String(fecha.getHours()).padStart(2, "0")}:${String(fecha.getMinutes()).padStart(2, "0")} ${String(fecha.getDate()).padStart(2, "0")}${sep}${String(fecha.getMonth() + 1).padStart(2, "0")}${sep}${fecha.getFullYear()}${sufix}`;

    //   },
      // indentifica(ctrl) {        
       
      //   ctrl.value= this.identificacion();      
      // },

      //
      changeReclamacion() {
        
      }

    },

    computed: {
      record: function() { return this.$store.state[this.padre].record; },

      // devuelvo si estamos en modo edición en el Mto
      is_edit() { 
        return this.$store.state[this.padre].estado === "editar" || this.$store.state[this.padre].estado === "nuevo"? true : false;
      },

      is_edit1() {
        return this.is_edit && this.record.apd1>0 && (this.app==1||this.rol=='jz'||(this.rol=='apds' && this.rolId==this.record.apd1))
      },

      is_edit2() {
        //console.log('***** **** **** exp_m_incidencias.is_edit2', this.app,this.rol,this.rolId,this.schema.ctrls,this.elem);
        return this.is_edit && this.record.apd2>0 && (this.app==1||this.rol=='jz'||(this.rol=='apds' && this.rolId==this.record.apd2))
      },
      
      get_motivos() {
        return this.$store.state.datos_iniciales.motivos_prefam;
      }
    }
  };
</script>
